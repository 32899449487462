import { CocktailImageProps } from "@/types";

const Wine = ({ backgroundColour, liquidColour }: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeLinejoin="round"
                strokeWidth="12"
                d="M436.5 743H204c-1.6 0-3-1.4-2.4-3 3-7 18-7 31.4-7 76 0 76-86 76-145.5 0-59 1.5-130-19.5-139.5 0 0 17.5 5 30 5s30-5 30-5c-24 10.3-19 83.5-19 139.5 0 59 0 145.5 70.5 145.5 13 0 33.5 0 37.7 7.1.9 1.5-.6 2.9-2.2 2.9Z"
            />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="M428 194H211.5c-1.2 0-2.3.7-2.7 1.8-3.6 9-19.3 52.3-19.3 118.7 0 75 47 136.5 131.5 136.5s131-68 131-136.5c0-61-17.5-109-21.3-118.7a2.9 2.9 0 0 0-2.7-1.8Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M400.8 94H238.7a3 3 0 0 0-2.8 2c-5.7 17.8-46.4 148.7-46.4 218.5 0 75 47 136.5 131.5 136.5s131-68 131-136.5c0-63.9-42.6-200.3-48.4-218.5a3 3 0 0 0-2.8-2Z"
            />
        </svg>
    );
};

export default Wine;
