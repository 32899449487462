import { CocktailImageProps } from "@/types";

const OldFashioned = ({
    backgroundColour,
    liquidColour,
}: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="m120 554.5-3.5-250.6a3 3 0 0 1 3.8-3 954.6 954.6 0 0 0 198 19.1c106 0 181-15.3 198.2-19.1a3 3 0 0 1 3.7 3v248.5c0 1-.4 1.8-1.1 2.3-9 6.5-72.7 48.7-197.6 48.7-124.7 0-190.8-40.3-200.3-46.6a2.8 2.8 0 0 1-1.2-2.3Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="m121.3 622.9-8.2-450.4a3 3 0 0 1 3.3-3 1747 1747 0 0 0 203.7 12.7c94.6-.2 184.7-10.6 203-12.8a3 3 0 0 1 3.3 3L518.2 623c0 .9-.5 1.7-1.2 2.3-9 6.2-72.1 46.6-196.9 46.6-124.8 0-188.4-40.4-197.6-46.6a2.8 2.8 0 0 1-1.2-2.3Z"
            />
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="m123.5 622.2-2.1-69.7s92.4 48.2 198.7 48.1c106.4 0 198.7-48.8 198.7-48.8l-2 70.4s-61 48.2-196.7 48.2-196.6-48.2-196.6-48.2Z"
            />
        </svg>
    );
};

export default OldFashioned;
