import { CocktailImageProps } from "@/types";

const Highball = ({ backgroundColour, liquidColour }: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <circle
                cx="449.6"
                cy="147.6"
                r="80"
                fill="#E3E742"
                transform="rotate(-16 449.6 147.6)"
            />
            <circle
                cx="449.7"
                cy="147.4"
                r="74.7"
                fill="#FFFBD7"
                transform="rotate(-16 449.7 147.4)"
            />
            <path
                fill="#ECF776"
                d="m401.7 95.5-14.6 21.4a4 4 0 0 0 1.7 6l44.6 18.4c3.7 1.5 7.1-2.9 4.7-6.1l-29.9-39.8a4 4 0 0 0-6.5 0Zm45.6-16.1-31.2 8a4 4 0 0 0-2.4 6l26.6 42.3a4 4 0 0 0 7.3-1.7l4.7-50.3a4 4 0 0 0-5-4.3Zm9.3 5.1-6.9 49.3a4 4 0 0 0 6.8 3.5l35.8-34.8a4 4 0 0 0-1-6.5l-28.9-14.5a4 4 0 0 0-5.8 3ZM499 105l-36.1 29.7c-3.3 2.7-.7 8 3.4 7l46.3-10.7a4 4 0 0 0 2.6-5.8l-10.2-19a4 4 0 0 0-6-1.2Zm14.8 32-50 7.2a4 4 0 0 0-1.3 7.4l43.3 24.4a4 4 0 0 0 5.9-2.7l6.6-31.6a4 4 0 0 0-4.5-4.8Zm-11.1 44.7L463 155.5c-3.5-2.3-7.7 1.6-5.7 5.2l23.4 43.6a4 4 0 0 0 6.5.8l16.2-17.4a4 4 0 0 0-.7-6ZM473 207.6l-20-48a4 4 0 0 0-7.6.4l-14.6 49.6a4 4 0 0 0 4 5.1l34.7-1.6a4 4 0 0 0 3.5-5.5Zm-79-28.7 43.3-24.8a4 4 0 0 1 5.7 5L424.3 206a4 4 0 0 1-6.4 1.5l-24.6-22.2a4 4 0 0 1 .7-6.4Zm-7.2-50.1 49.4 15.7a4 4 0 0 1 .4 7.5l-46 21.5a4 4 0 0 1-5.7-3.3l-3.3-37.3a4 4 0 0 1 5.2-4.1Z"
            />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="M207.5 667.5 197 244s79.9 4.2 129.5 4.2c49.6 0 122-4.2 122-4.2l-10 423.5s-69.5 17.7-113.5 18c-46.1.3-117.5-18-117.5-18Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="m208.4 727.2-12.3-576a3 3 0 0 1 3.1-3c15.4 1 82.6 4.8 126.3 4.8 43.4 0 106.3-3.8 121.2-4.8a3 3 0 0 1 3.2 3l-12.8 576a3 3 0 0 1-2.3 2.8c-12 2.8-70 15.7-109.3 16-41.3.3-102.4-13.2-114.8-16a3 3 0 0 1-2.3-2.8Z"
            />
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M208.5 727.5 207 662s42 13 118.5 13 113-13 113-13l-1.5 65.6c0 1.2-.7 2.2-1.7 2.6-8 3.1-43.5 15.3-109.8 15.8-66.7.5-106.5-12.6-115.2-15.8a2.9 2.9 0 0 1-1.8-2.7Z"
            />
        </svg>
    );
};

export default Highball;
