import { CocktailImageProps } from "@/types";

const ChampagneFlute = ({
    backgroundColour,
    liquidColour,
}: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <path
                fill="#9CDBEA"
                d="M410 766H237.5c-1.7 0-3.2-1.4-2.5-3 3-7 18-7 31.4-7 46 0 46-86 46-145.5 0-56.8 1.4-128.4-17.3-142.2L293 467l2.2 1.3c5.2 3 18 9.7 27.8 9.7a65.5 65.5 0 0 0 30-11l-3 1.8c-20.6 15.5-16 88.2-16 141.7 0 59 0 145.5 40.5 145.5 13 0 33.5 0 37.7 7.1.9 1.5-.6 2.9-2.3 2.9Z"
            />
            <path
                stroke="#9CDBEA"
                strokeLinejoin="round"
                strokeWidth="12"
                d="M374.5 756c13 0 33.5 0 37.7 7.1.9 1.5-.6 2.9-2.3 2.9H237.6c-1.7 0-3.2-1.4-2.5-3 3-7 18-7 31.4-7 46 0 46-86 46-145.5 0-59 1.5-134-19.5-143.5 0 0 17.5 11 30 11s30-11 30-11c-24 10.3-19 87.5-19 143.5 0 59 0 145.5 40.5 145.5Z"
            />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="M398.2 196.5H247.8a2.9 2.9 0 0 0-3 2.7c-1.9 28.7-15.6 280 78.2 279.8 93.8-.1 80-251.1 78.2-279.8a2.9 2.9 0 0 0-3-2.7Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M391.3 116.5H254.2c-1.6 0-2.8.9-3 2.4C247.7 150.3 213 479.1 323 479c110-.1 74.9-328.7 71.3-360.1-.2-1.5-1.4-2.4-3-2.4Z"
            />
        </svg>
    );
};

export default ChampagneFlute;
