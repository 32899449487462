import { CocktailImageProps } from "@/types";

const Martini = ({ backgroundColour, liquidColour }: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <circle
                cx="515"
                cy="105"
                r="80"
                fill="#6AC268"
                transform="rotate(-16 515 105)"
            />
            <circle
                cx="515"
                cy="104.8"
                r="74.7"
                fill="#C0FFCA"
                transform="rotate(-16 515 104.8)"
            />
            <path
                fill="#8AE074"
                d="m467.1 52.9-14.7 21.4a4 4 0 0 0 1.8 6l44.5 18.3c3.8 1.6 7.2-2.8 4.8-6l-29.9-39.9a4 4 0 0 0-6.5.2Zm45.6-16.1-31.2 8a4 4 0 0 0-2.4 5.9l26.5 42.4c2 3.3 7 2 7.4-1.7l4.6-50.3a4 4 0 0 0-5-4.3ZM522 42l-7 49.2a4 4 0 0 0 6.8 3.4L557.7 60a4 4 0 0 0-1-6.5l-29-14.5a4 4 0 0 0-5.7 3Zm42.3 20.4-36 29.7c-3.3 2.7-.7 8 3.4 7L578 88.4a4 4 0 0 0 2.6-5.8l-10.2-19a4 4 0 0 0-6-1.2Zm14.8 31.9-49.9 7.2a4 4 0 0 0-1.4 7.5l43.4 24.4a4 4 0 0 0 5.8-2.7l6.6-31.6a4 4 0 0 0-4.5-4.8ZM568 139l-39.5-26c-3.5-2.3-7.7 1.6-5.7 5.2l23.4 43.6a4 4 0 0 0 6.4.8l16.3-17.4a4 4 0 0 0-.7-6Zm-29.6 26-20-48a4 4 0 0 0-7.6.4L496.2 167a4 4 0 0 0 4 5.1l34.7-1.6a4 4 0 0 0 3.5-5.6Zm-79-28.7 43.3-24.8a4 4 0 0 1 5.7 5l-18.7 46.9a4 4 0 0 1-6.4 1.5l-24.6-22.2a4 4 0 0 1 .7-6.4Zm-7.3-50.2 49.4 15.8a4 4 0 0 1 .5 7.4L456 131a4 4 0 0 1-5.7-3.3l-3.4-37.3a4 4 0 0 1 5.2-4.2Z"
            />
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M292.2 362.5h27.6a3 3 0 0 1 3 3v360c0 1 .6 2 1.4 2.5l68.6 43.8a3 3 0 0 1 1.4 2.5v9.2a3 3 0 0 1-3 3H222.8a3 3 0 0 1-3-3v-9.2c0-1 .5-2 1.4-2.5l66.6-43.8a3 3 0 0 0 1.4-2.5v-360a3 3 0 0 1 3-3Z"
            />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="M471.4 164.4H138.6l148.2 198.1h38.9l145.7-198Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M509 105H101a3 3 0 0 0-2.4 4.8l189.7 255.5a3 3 0 0 0 2.4 1.2h30.6a3 3 0 0 0 2.4-1.2l187.8-255.5a3 3 0 0 0-2.4-4.8Z"
            />
        </svg>
    );
};

export default Martini;
