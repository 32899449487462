import { CocktailImageProps } from "@/types";
import { CocktailGlass } from "@prisma/client";

import ChampagneFlute from "./Cocktails/ChampagneFlute";
import Highball from "./Cocktails/Highball";
import Hurricane from "./Cocktails/Hurricane";
import Martini from "./Cocktails/Martini";
import OldFashioned from "./Cocktails/OldFashioned";
import Shot from "./Cocktails/Shot";
import Wine from "./Cocktails/Wine";

const CocktailImage = (props: CocktailImageProps) => {
    if (props.glass === undefined) {
        return <span>No Glass</span>;
    }

    let Glass;
    switch (props.glass) {
        case CocktailGlass.MARTINI:
            Glass = Martini;
            break;

        case CocktailGlass.HIGHBALL:
            Glass = Highball;
            break;

        case CocktailGlass.OLD_FASHIONED:
            Glass = OldFashioned;
            break;

        case CocktailGlass.HURRICANE:
            Glass = Hurricane;
            break;

        case CocktailGlass.CHAMPAGNE:
            Glass = ChampagneFlute;
            break;

        case CocktailGlass.SHOT:
            Glass = Shot;
            break;

        case CocktailGlass.WINE:
            Glass = Wine;
            break;
    }

    return <Glass {...props} />;
};

export default CocktailImage;
