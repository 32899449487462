import { CocktailImageProps } from "@/types";

const Hurricane = ({ backgroundColour, liquidColour }: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <g filter="url(#a)">
                <rect
                    width="20"
                    height="567.907"
                    x="227.356"
                    y="32.2076"
                    fill="url(#b)"
                    rx="3"
                    shapeRendering="crispEdges"
                    transform="rotate(-18.3348 227.356 32.2076)"
                />
            </g>
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M297.246 683.547c.326-16.652-20.671-45.362-20.671-45.362s24.785 6.207 41.916 6.316c17.567.112 44.214-6.316 44.214-6.316s-19.756 26.077-19.523 45.362c.233 19.284 12.058 23.542 12.058 35.6 0 10.436-3.011 13.13-9.032 21.856-1.345 1.948.138 4.566 2.505 4.624 80.166 1.976 90.973 46.903 92.43 58.98.198 1.645-1.116 2.966-2.773 2.966H199.761c-1.657 0-2.97-1.321-2.767-2.965 1.486-12.045 12.492-56.77 93.949-58.964 2.514-.068 3.953-2.977 2.339-4.907l-.183-.218c-6.262-7.488-9.06-10.833-9.06-21.372 0-12.632 12.881-18.949 13.207-35.6Z"
            />
            <path
                fill={liquidColour}
                fillOpacity=".75"
                d="M187 504.971c0-81.536 43.639-183.744 43.639-264.131h180.298c0 78.091 42.491 181.446 42.491 264.131 0 82.684-51.678 138.956-135.511 138.956S187 586.507 187 504.971Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M230.639 240.84c0-63.121-14.161-97.92-20.244-109.958-1.081-2.138.486-4.882 2.882-4.882h213.202c2.294 0 3.843 2.495 2.915 4.593-5.335 12.054-18.457 48.028-18.457 110.247 0 78.091 42.491 181.446 42.491 264.131 0 82.684-51.678 138.956-135.511 138.956S187 586.507 187 504.971c0-81.536 43.639-183.744 43.639-264.131Z"
            />
            <defs>
                <pattern
                    id="b"
                    width="3"
                    height=".1057"
                    patternContentUnits="objectBoundingBox"
                >
                    <use xlinkHref="#c" transform="scale(0.075 0.00264128)" />
                </pattern>
                <filter
                    id="a"
                    width="200.049"
                    height="547.787"
                    x="226.147"
                    y="26.7068"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_66_177"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_66_177"
                        result="shape"
                    />
                </filter>
                <image
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAq0lEQVRYhcXWOw6AQBACUNb733mtTDS6Oh9AevJCx5hoZNbb2y8qxqjCPRXFxW21BDPUPExSkzBPzcBUNQyz1RgsUAOwRv2CZeorrFTXsFhdwHr1CbaoN9ilXmGjeoK96gHbVQDbLypan6uhYs4q3FNRXNxWSzBDzcMkNQnz1AxMVcMwW43BAjUAa9QvWKa+wkp1DYvVBaxXn2CLeoNd6hU2qifYqx6wXQWwA/r9UU1Sp1gCAAAAAElFTkSuQmCC"
                    id="c"
                    width="40"
                    height="40"
                />
            </defs>
        </svg>
    );
};

export default Hurricane;
