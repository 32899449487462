import { CocktailImageProps } from "@/types";

const Shot = ({ backgroundColour, liquidColour }: CocktailImageProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 640 840"
        >
            <path fill={backgroundColour} d="M0 0h640v840H0z" />
            <path
                fill={liquidColour}
                fillOpacity=".8"
                d="M426.5 233.4h-212a3 3 0 0 0-3 4c14 49.8 14.1 115.2 21 207.4.2 1.4 12 98.2 89.2 98.2 76.6 0 89.3-95.5 89.6-98.1v-.2c3.3-87.8 1.8-148.9 18.1-207.4a3 3 0 0 0-2.9-3.9Z"
            />
            <path
                fill="#9CDBEA"
                stroke="#9CDBEA"
                strokeLinejoin="round"
                strokeWidth="12"
                d="M321.7 646.8c48.2 0 79-14.2 86-17.8 1-.4 1.5-1.4 1.5-2.5-.2-12.5-1-91.2 2.4-181.7 0 0-12.2 98.2-90 98.2-77.6 0-89-98.2-89-98.2 2.9 90.3 3 169.5 3.1 181.8 0 1 .6 2 1.5 2.4 6.7 3.6 36.3 17.8 84.5 17.8Z"
            />
            <path
                fill="#9CDBEA"
                fillOpacity=".3"
                stroke="#9CDBEA"
                strokeWidth="12"
                d="M436 194H205a3 3 0 0 0-2.9 3.8c14.5 51.9 23.5 154.8 30.4 247 .2 1.4 12 98.2 89.2 98.2 76.6 0 89.5-95.4 89.9-98.1v-.2C415 356.9 422.2 258 439 197.8a3 3 0 0 0-2.9-3.8Z"
            />
        </svg>
    );
};

export default Shot;
